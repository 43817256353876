/* eslint-disable react/forbid-component-props */
import {
  faChevronLeft,
  faChevronRight,
  faLock,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { useSelector } from 'react-redux';
import { TRAVEL_DEAL_QUALIFIERS } from '../../../config/constants/page-types';
import {
  SITE_WOWCHER,
  SITE_LIVINGSOCIAL,
  SITE_LIVINGSOCIAL_IE,
} from '../../../config/setup/setup';
import { VIP } from '../../../config/text/text';
import { getLocationShortName } from '../../../helpers/location';
import {
  makeUrlAbsolute,
  useNavigationSelection,
  parseWowcherPath,
} from '../../../helpers/url';
import ThemeContext from '../../../providers/ThemeProvider';
import HeaderNavigationLink from './HeaderNavigationLink';
import HeaderNavigationPopover from './HeaderNavigationPopoverDynamic';
import More from './More';
import MorePopover from './MorePopoverDynamic';
import { TravelMenu } from './TravelMenu/TravelMenu';

const HeaderNavigation = ({ site, navigation, ssr, location, path }) => {
  const theme = useContext(ThemeContext);
  const [arrowActive, setArrowActive] = useState({ left: false, right: true });
  const selection = useNavigationSelection(navigation, path);
  const isVipUser = useSelector((state) => state.user.userprofile?.vipUser);
  const isAuthCheckFinished = useSelector(
    (state) => state.user.isAuthCheckFinished,
  );
  const shortName = getLocationShortName(location);
  const [ready, setReady] = useState(false);
  const router = useRouter();
  const {
    details: { isTravel },
  } = parseWowcherPath(router.asPath);
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    setFilteredArray(navigation);
  }, [navigation]);

  const travelCats = navigation.find((item) =>
    TRAVEL_DEAL_QUALIFIERS.includes(item.shortName),
  );

  const onScroll = (event) => {
    setArrowActive({
      left: event.target.scrollLeft > 0,
      right:
        event.target.scrollLeft <
        event.target.scrollWidth - event.target.offsetWidth,
    });
  };

  return (
    <>
      <div className="nav-bar">
        {/* LEFT ARROW */}
        <div
          className={classNames('scroll-arrow', 'scroll-arrow--left', {
            'scroll-arrow--active': arrowActive.left,
          })}
        >
          <FontAwesomeIcon className="arrow-right" icon={faChevronLeft} />
        </div>
        {/* CONTENT */}
        <nav className="container" onScroll={onScroll}>
          <ul className="navigation__list">
            {filteredArray.length === 0 && <div />}
            {filteredArray.map((item) => {
              const travelSelected =
                isTravel && TRAVEL_DEAL_QUALIFIERS.includes(item.shortName);
              const isVipHub = item.shortName === VIP.toLowerCase();

              const isItemLocalTravel = isTravel && item?.isLocal;

              return (
                <HeaderNavigationLink
                  dataTestId={item.shortName}
                  disabled={ssr && item.isLocalLink && !item.isLocal}
                  href={
                    item.isLocal
                      ? makeUrlAbsolute(`/deals/${shortName}`)
                      : item.url
                  }
                  isTravelSelected={travelSelected}
                  key={item.id}
                  popover={
                    travelSelected ? null : (
                      <HeaderNavigationPopover category={item} site={site} />
                    )
                  }
                  selected={isItemLocalTravel ? false : selection === item.id}
                  shouldAutoScrollIntoView
                >
                  {item.isLocal && (
                    <>
                      <FontAwesomeIcon
                        className="navigation__icon"
                        icon={faMapMarkerAlt}
                      />
                      <ReactPlaceholder
                        ready={ready}
                        rows={1}
                        style={{ minWidth: 60 }}
                        type="text"
                      >
                        <span style={{ minWidth: 60 }}>{location.name}</span>
                      </ReactPlaceholder>
                    </>
                  )}
                  {!item.isLocal && item.linkText}
                  {/* For a user is not VIP, we added additional Lock Icon on VIP hub */}
                  {isVipHub && !isVipUser && isAuthCheckFinished && (
                    <span style={{ marginLeft: 6 }}>
                      <FontAwesomeIcon
                        className="navigation__icon"
                        icon={faLock}
                      />
                    </span>
                  )}
                </HeaderNavigationLink>
              );
            })}

            <More
              popover={
                <MorePopover
                  navigation={filteredArray}
                  selection={selection}
                  site={site}
                />
              }
            />
          </ul>
        </nav>
        {/* RIGHT ARROW */}
        <div
          className={classNames('scroll-arrow', 'scroll-arrow--right', {
            'scroll-arrow--active': arrowActive.right,
          })}
        >
          <FontAwesomeIcon className="arrow-right" icon={faChevronRight} />
        </div>
      </div>

      {isTravel && <TravelMenu list={travelCats?.subNavigations} />}

      <style jsx>{`
        .nav-bar {
          position: relative;
          background-color: ${theme.colors.navbackground};
          border-bottom: ${isTravel
            ? '0'
            : '1px solid' + theme.colors.navborder};
          border-top: ${'1px solid' + theme.colors.navborder};
          display: block;
          z-index: 1001;
        }
        .container {
          position: relative;
          z-index: 100;
          padding: 0 25px;
          display: flex;
        }
        .navigation__list {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          height: 36px;
          margin: 0;
          padding: 0;
        }
        :global(.navigation__icon) {
          margin-right: 5px;
          position: relative;
          top: -1px;
          height: 14px;
          width: 14px;
        }
        .scroll-arrow {
          position: absolute;
          top: 0;
          height: 36px;
          width: 72px;
          border: none;
          align-items: center;
          z-index: 101;
          color: ${theme.colors.navlinkactive};
          animation-fill-mode: backwards;
          pointer-events: none;
          opacity: 0;
          display: flex;
        }
        .scroll-arrow--left {
          left: 0;
          background-image: linear-gradient(
            to right,
            white 25%,
            rgba(255, 255, 255, 0)
          );
          justify-content: flex-start;
          padding-left: 10px;
        }
        .scroll-arrow--right {
          right: 0;
          background-image: linear-gradient(
            to left,
            white 25%,
            rgba(255, 255, 255, 0)
          );
          justify-content: flex-end;
          padding-right: 10px;
        }
        :global(.arrow-right) {
          height: 16px;
          width: 16px;
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .container {
            padding: 0;
            overflow: auto;
          }
          .scroll-arrow {
            transition: opacity 0.2s;
          }
          .scroll-arrow--active {
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
};

HeaderNavigation.propTypes = {
  location: PropTypes.object,
  navigation: PropTypes.array,
  path: PropTypes.string,
  site: PropTypes.oneOf([
    SITE_WOWCHER,
    SITE_LIVINGSOCIAL,
    SITE_LIVINGSOCIAL_IE,
  ]),
  ssr: PropTypes.bool,
};

export default HeaderNavigation;
