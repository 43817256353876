import { useEffect, useMemo, useState } from 'react';
import Cookies from 'react-cookies';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import COOKIES from '../config/cookies/cookies';
import { BRAND_WOWCHER, SEARCHURL, URLDEAL } from '../config/setup/setup';
import { cutArray } from './deals';
import { makeUrlAbsolute } from './url';

export const siteToApiBrand = (site) => {
  switch (site) {
    case 'livingsocial':
      return 'living-social';
    case 'livingsocialie':
      return 'living-social-ie';
    default:
      return 'wowcher';
  }
};

export const findConfigFromPathAndPosition = (configs, path, position) => {
  return configs.find((config) => {
    return (
      path.includes(config.siteLocation) &&
      config.pageLocation === position.toString()
    );
  });
};

export const getUrlFromMerchandisingConfig = (config) => {
  const location =
    config?.location?.toLowerCase() ||
    Cookies.load(COOKIES.location)?.shortName;
  if (config?.specialSearchTag) {
    return `${URLDEAL}/${location}/special/${config?.specialSearchTag?.toLowerCase()}?pageSize=14`;
  } else if (config?.searchTerm) {
    return `${SEARCHURL}/${location}?q=${config?.searchTerm}&pageSize=18`;
  } else {
    const path = [
      URLDEAL,
      location,
      config.category?.toLowerCase(),
      config.subCategory?.toLowerCase(),
    ];

    return `${path.filter((index) => index).join('/')}?pageSize=14&page=1`;
  }
};

const fetchMerchandising = async (url) => {
  const response = await axios({
    headers: {
      brand: process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER,
    },
    method: 'GET',
    url,
  });

  return response?.data?.deals || [];
};

export const useMerchandisingModuleConfig = (
  path,
  position,
  isEarlyBird = false,
) => {
  const data = useSelector((state) => state.scroller.config);
  const [apiUrl, setApiUrl] = useState(null);
  const [config, setConfig] = useState({});

  const { data: deals } = useSWR(apiUrl, fetchMerchandising);

  useEffect(() => {
    const moduleConfig = findConfigFromPathAndPosition(data, path, position);
    if (moduleConfig) {
      const url = !isEarlyBird && getUrlFromMerchandisingConfig(moduleConfig);
      setApiUrl(url);
      setConfig({
        backgroundImage: moduleConfig.backgroundImage,
        ctaLabel: moduleConfig.ctaLabel,
        ctaLink: makeUrlAbsolute(moduleConfig.ctaLink),
        header: moduleConfig.header,
        subHeader: moduleConfig.subHeader,
      });
    }
  }, [data, path, position]);

  return { ...config, deals: deals || [] };
};

/** how many deals can we show on each page */
export const getCountFromBreakpoint = (breakpoint, isTravel = false) => {
  switch (breakpoint) {
    case 'sm':
      return 1;
    case 'md':
      return 2;
    case 'lg':
      return 2;
    default:
      return isTravel ? 3 : 4;
  }
};

/** slice up deals into complete pages, disregard incomplete pages */
export const makePages = (deals, count) => {
  const pageRunOver = deals.length % count;
  const tidyPages = pageRunOver > 0 ? deals.slice(0, pageRunOver * -1) : deals;

  return cutArray(tidyPages, count);
};

/** memo version of above to avoid calling on all page resize events */
export const usePages = (deals, count) => {
  return useMemo(() => makePages(deals, count), [deals, count]);
};
