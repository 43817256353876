import { COMMON_COLORS } from '../constants/common';
import livingsocial from './livingsocial';
import wowcher from './wowcher';

const christmas = {
  livingsocial: {
    ...livingsocial,
    images: {
      ...livingsocial.images,
      bgcolorwash:
        'https://resources.wowcher.co.uk/images/ls/livingsocial-xmas-colourwash.jpg',
      headerlogo:
        'https://resources.wowcher.co.uk/images/ls/livingsocial-xmas-logo--black.svg',
    },
  },
  wowcher: {
    ...wowcher,
    colors: {
      ...wowcher.colors,
      breadcrumb: COMMON_COLORS.white,
      breadcrumbpale: COMMON_COLORS.white,
      primarypromotion: COMMON_COLORS.white,
    },
    images: {
      ...wowcher.images,
      bgcolorwash:
        'https://resources.wowcher.co.uk/assets/img/brand/wowcher/colorwash/Wow-Xmas-colorwash.jpg',
      headerlogo:
        'https://resources.wowcher.co.uk/assets/img/brand/wowcher/logo/Wow-Christmas-logo.png',
    },
    styles: {
      ...wowcher.styles,
      breadcrumbtextshadow: `-1px -1px 0 #C31420, 1px -1px 0 #C31420, -1px 1px 0 #C31420,
      1px 1px 0 #C31420;`,
    },
  },
};

export default christmas;
