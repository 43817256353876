import { DEALS } from '../../config/constants/action-types';
import { KEY_NEW_PRODUCT_DEALS } from '../../config/constants/key-payload-types';
import {
  DEFAULT_LOCATION,
  URLEVENTDEAL,
  EVENT_NAME_NEW_PRODUCTS,
  BRAND_WOWCHER,
} from '../../config/setup/setup';
import { isSSR } from '../../helpers/ssr';
import { getIsShopLayout, parseWowcherPath } from '../../helpers/url';
import { getDeals, getDealsData } from './deals';
import { getLocations } from './locations';
import { setMerchant } from './merchant';
import { getNavigation } from './navigation';
import { getTiles } from './tiles';

export const getServerSideEssentials = (url) => {
  if (!url) {
    throw new Error('Requires url');
  }

  return async (dispatch) => {
    const location =
      DEFAULT_LOCATION[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER];
    dispatch(setMerchant(process.env.MERCHANT_URL));

    // don't refetch navigation/locations if we are navigating inside the application
    if (url.includes('/redirects.json') || !url.includes('/_next/data')) {
      const requests = [
        // Get/Set locations list, which is used in navigation
        dispatch(getLocations()),
        // Get/Set the list of navigations
        dispatch(getNavigation(location, isSSR())),
      ];

      return Promise.all(requests);
    }

    return Promise.resolve();
  };
};

export const getServerSideDeals = ({
  query = {},
  dealId = null,
  secondaryDealsCount,
  path,
}) => {
  const ssr = isSSR();
  const { pageType } = parseWowcherPath(path);
  const isShopLayout = getIsShopLayout(pageType);
  const location = isShopLayout ? 'london' : query.slug[0];

  return async (dispatch) => {
    if (!ssr) {
      return Promise.resolve([]);
    }
    const requests = [
      dispatch(
        getDeals({
          dealId,
          isCategoryPage: true,
          path,
          secondaryDealsCount,
          // slug is passed but getDealApiPath doesnt accomodate query params yet
          slug: query.slug,
          ssr,
        }),
      ),
      // setting new products in the deals reducer
      dispatch(
        getDealsData(
          KEY_NEW_PRODUCT_DEALS,
          URLEVENTDEAL(location, EVENT_NAME_NEW_PRODUCTS),
          DEALS.SET_NEW_PRODUCT_DEALS,
        ),
      ),
      dispatch(getTiles(path)) || null,
    ];

    return Promise.all(requests);
  };
};

export const getServerSideDeal = ({
  query = {},
  dealId = null,
  secondaryDealsCount,
  byPassSSR = null,
  path,
  pageSize = null,
  isEmailDeal,
  emailDealLocation,
  sideDealsLocal,
}) => {
  const ssr = byPassSSR === null ? isSSR() : byPassSSR;
  // SSR gets passed into the deals state which we should be relying directly on the source of truth

  return async (dispatch) => {
    const baseParameters = {
      dealId,
      emailDealLocation,
      isEmailDeal,
      path,
      secondaryDealsCount,
      sideDealsLocal,
      // slug is passed but getApiPath doesnt accomodate query params yet
      slug: query.slug,
      ssr,
    };
    const dealsParameters = pageSize
      ? { ...baseParameters, pageSize }
      : baseParameters;
    const requests = [dispatch(getDeals(dealsParameters))];

    return Promise.all(requests);
  };
};
