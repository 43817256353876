/* eslint-disable filenames/match-regex */
// ACTION TYPES

export const USER = {
  RESET_USER: 'RESET_USER',
  SET_AUTH_CHECK_FINISHED: 'SET_AUTH_CHECK_FINISHED',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  SET_LIGHTBOX: 'SET_LIGHTBOX',
  SET_USER: 'SET_USER',
};

export const USER_EMAIL = { SET_USER_EMAIL: 'SET_USER_EMAIL' };

export const LOADING_STATE = {
  SET_LOADING_STATUS: 'SET_LOADING_STATUS',
};

export const LOCATIONS = {
  GET_LOCATIONS: 'GET_LOCATIONS',
  SET_LOCATION: 'SET_LOCATION',
  SET_LOCATIONS: 'SET_LOCATIONS',
};

export const MERCHANT = {
  SET_MERCHANT: 'SET_MERCHANT',
};

export const TILES = {
  GET_TILES: 'GET_TILES',
  SET_TILES: 'SET_TILES',
};

export const DEALS = {
  GET_DEALS: 'GET_DEALS',
  SET_ALL_FEATURED_DEALS_LOADED: 'SET_ALL_FEATURED_DEALS_LOADED',
  SET_DEALS: 'SET_DEALS',
  SET_EXTRA_DEALS: 'SET_EXTRA_DEALS',
  SET_FOR_DEAL_PREVIEW: 'SET_FOR_DEAL_PREVIEW',
  SET_IS_DEAL_PREVIEW: 'SET_IS_DEAL_PREVIEW',
  SET_LAST_ID: 'SET_LAST_ID',
  SET_NEW_PRODUCT_DEALS: 'SET_NEW_PRODUCT_DEALS',
  SET_NO_MORE_DEALS: 'SET_NO_MORE_DEALS',
  SET_ONLY_MAIN_DEAL: 'SET_ONLY_MAIN_DEAL',
  SET_ONLY_SSR: 'SET_ONLY_SSR',
  SET_RECENTLY_VIEWED_DEALS: 'SET_RECENTLY_VIEWED_DEALS',
  SET_RECOMMENDED_DEALS: 'SET_RECOMMENDED_DEALS',
  SET_WITHOUT_MAIN_DEAL: 'SET_WITHOUT_MAIN_DEAL',
};

export const SCROLLER = { SET_SCROLLER: 'SET_SCROLLER' };

export const NAVIGATION = {
  SET_DEAL_PREVIEW: 'SET_DEAL_PREVIEW',
  SET_MENU: 'SET_MENU',
  SET_SSR: 'SET_NAVIGATION_SSR',
};

export const BASKET = {
  RESET_BASKET: 'RESET_BASKET',
  SET_BASKET: 'SET_BASKET',
};

export const CHECKOUT = {
  CHECKOUT_API_PARAMS: 'CHECKOUT_API_PARAMS',
  RESET_COUNTDOWN: 'CHECKOUT_RESET_COUNTDOWN',
  SET_CHECKOUT: 'SET_CHECKOUT',
  SET_CHECKOUT_STATUS: 'SET_CHECKOUT_STATUS',
  SET_TRAVEL: 'SET_TRAVEL',
  SET_TRAVEL_VALID: 'SET_TRAVEL_VALID',
};

export const WALLET = {
  ERROR_WALLET: 'ERROR_WALLET',
  RESET_WALLET: 'RESET_WALLET',
  SET_WALLET: 'SET_WALLET',
};

export const BCOR = {
  ERROR_BCOR: 'ERROR_BCOR',
  RESET_BCOR: 'RESET_BCOR',
  SET_CURRENT_DEAL_ID: 'SET_CURRENT_DEAL_ID',
  SET_FILTERED_PRODUCTS: 'SET_FILTERED_PRODUCTS',
  SET_NIGHT_OPTIONS: 'SET_NIGHT_OPTIONS',
  SET_PRODUCTS: 'SET_PRODUCTS',
  SET_QUANTITY: 'SET_QUANTITY',
  SET_SELECTED_NIGHTS: 'SET_SELECTED_NIGHTS',
  SET_SELECTION: 'SET_SELECTION',
};

export const FILTERS = {
  EMPTY_FILTERS: 'EMPTY_FILTERS',
  GET_FILTERS: 'GET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_EXTRA_FILTERS: 'SET_EXTRA_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  SET_NO_MORE_FILTERS: 'SET_NO_MORE_FILTERS',
  SET_URL_PATH: 'SET_URL_PATH',
};

export const GIFTING = {
  RESET_GIFTING: 'RESET_GIFTING',
  SET_GIFTING: 'SET_GIFTING',
  SET_NDD_GIFTING: 'SET_NDD_GIFTING',
  SET_STD_GIFTING: 'SET_STD_GIFTING',
};

export const VIDEO = {
  PLAY: 'VIDEO_PLAYING',
  SET_AUTO_PLAYABLE: 'SET_VIDEO_AUTO_PLAYABLE',
  SET_VISIBILITY: 'SET_VIDEO_VISIBILITY',
  STOP: 'VIDEO_STOP',
};

export const PAGE_TYPE = { SET_PAGE_TYPE: 'SET_PAGE_TYPE' };

export const WISHLIST = {
  RESET_WISHLIST: 'RESET_WISHLIST',
  SET_WISHLIST: 'SET_WISHLIST',
};

export const FOOTERS = {
  SET_FOOTER: 'SET_FOOTER',
};

export const TRACKING = {
  SET_TRACKING_DEALS: 'SET_TRACKING_DEALS',
};
