/* eslint-disable complexity, sonarjs/cognitive-complexity, import/no-unassigned-import, react-hooks/exhaustive-deps */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cookies from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';
import { OpenInApp } from '../components/OpenInApp';
import LoadingPlaceholder from '../components/_generic/loading-placeholder/LoadingPlaceholder';
import { getLocationFromSiteID } from '../components/deal/ConnectedBottomDeals/helpers';
import SubscribeModal from '../components/forms/SubscribeModalDynamic';
import { PAGE_TYPES } from '../config/constants/page-types';
import COOKIES from '../config/cookies/cookies';
import { BRAND_WOWCHER, LIGHTBOX_APPEARED } from '../config/setup/setup';
import themes from '../config/themes/themes';
import { updatePersistentProperty, waitForDtm } from '../helpers/analytics';
import { sendBulkDataWhenAuthenticated } from '../helpers/clubWowcher';
import commonCookiesOptions from '../helpers/commonCookiesOptions';
import {
  cookieMonsterInit,
  isOfflineLightbox,
  setCookie,
  removeCookie,
} from '../helpers/cookieSetter';
import { generateUEID } from '../helpers/generateID';
import { reSubscribeUsers } from '../helpers/lambdaCall';
import { isLightBoxAllowed } from '../helpers/lightbox';
import { isLoginPages, isMyaccountPages } from '../helpers/pages';
import { isChristmas, isBlackFriday } from '../helpers/promotions';
import { isSSR } from '../helpers/ssr';
import { parseWowcherPath } from '../helpers/url';
import { getVersionNumber } from '../helpers/version';
import HeaderFooterLayout from '../layouts/HeaderFooterLayout';
import { PurchaseProvider } from '../providers/PurchaseProvider';
import { ThemeProvider } from '../providers/ThemeProvider';
import { ToastProvider } from '../providers/ToastProvider';
import { getLocation } from '../redux/actions/locations';
import { setLightbox } from '../redux/actions/user';
import { wrapper } from '../redux/store/store';
import '../scss/custom.scss';
import '../scss/components/tabs.scss';
import 'leaflet/dist/leaflet.css';

const GoogleOneTap = dynamic(
  () => import('../components/mediaButtons/GoogleOneTap'),
  {
    ssr: false,
  },
);
const GeoLocation = dynamic(
  () => import('../components/mediaButtons/GeoLocation'),
  {
    ssr: false,
  },
);
const Toast = dynamic(() => import('../components/_generic/toast/Toast'), {
  ssr: false,
});

const ThemeSwitcher = dynamic(() => import('../components/ThemeSwitcher'), {
  ssr: false,
});

const MyApp = ({ Component, pageProps }) => {
  // NEXT_PUBLIC_BRAND has the brand used by default
  const environmentBrand =
    themes[process.env.NEXT_PUBLIC_BRAND] || themes.wowcher;
  const [theme, setTheme] = useState(environmentBrand);
  const [lightbox] = useSelector((state) => [state.user.lightbox]);
  const [urlHistory, setUrlHistory] = useState(null);
  const Layout = Component.layout || HeaderFooterLayout;

  const [showSubModal, setShowSubModal] = useState(false);
  const [isAuthenticated, isAuthCheckFinished] = useSelector((state) => [
    state.user.isAuthenticated,
    state.user.isAuthCheckFinished,
  ]);
  const [isBadConnection, setIsBadConnection] = useState(true);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [dtmAvailable, setDtmAvailable] = useState(true);
  const [showGeoLocation, setShowGeoLocation] = useState(false);
  const [showOneTap, setShowOneTap] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const ref = useRef(null);
  const customerToken = Cookies.load(COOKIES.customerToken);
  const validSubscription = Cookies.load(COOKIES.validSubscription);

  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations.locations);

  const router = useRouter();

  const printBlockingScripts = () => {
    if (typeof window !== 'undefined') {
      const urlParameters = new URLSearchParams(window.location.search);
      const printScripts = urlParameters.get('performanceCheck') === 'true';
      if (printScripts) {
        console.log('Start of blocking scripts log');
        window.performance
          .getEntriesByType('resource')
          // filter by blocking scripts only
          .filter(
            ({ renderBlockingStatus }) => renderBlockingStatus === 'blocking',
          )
          .forEach(({ name }) => console.log(name));
        console.log('End of blocking scripts log');
      }
    }
  };

  const showLightbox =
    !Cookies.load(COOKIES.awinSource) &&
    !isAuthenticated &&
    !Cookies.load(COOKIES.brandCookie);

  const fireGoogleGeoLocation = Cookies.load(COOKIES.googleLocation) !== 'b';
  let lightboxAppeared = false;
  if (typeof window !== 'undefined') {
    lightboxAppeared = JSON.parse(localStorage.getItem(LIGHTBOX_APPEARED));
  }

  // we switch offline lightbox off
  const hideOfflineLightbox = true;

  const callDigiohLightbox = () => {
    const [, queryString] = router.asPath.split(/\?|#/g);
    // Dont show Lightbox on organic guides page
    if (!queryString) {
      const isGuideURL = /\/guides/i;
      if (isGuideURL.test(document.location.href)) {
        return;
      }
    }
    const searchParameters = new URLSearchParams(queryString?.toLowerCase());
    const itoValue = searchParameters.get('ito');

    // Additional code for suppressing Digioh added from DTM's code

    const urlToExclude = /\/ppc|\/checkout|\/faqs|\/merchantfaqs|\/wowcher-dream-job\/wowcher-affiliates|marketplace.wowcher.co.uk|\/welcome|refer-a-friend|\/internetsafety/i;
    const lbSupressedChannels = /tth_salesgen|wowcher_affiliate|criteo|awin|wowcher_cloudiq|salescycle|wowcher_social_dyn-ret-new|wowcher_social_%20dyn-ret-new|tth-dyn-ret|catalogue_2020|wowcher_retarget/i;
    const lbReactRegex = /_react_r|_react_a|_react2_r|_react2_a|_urlstart.*urlend/i;
    const dodSessionSourceValue = Cookies.load(COOKIES.sessionSource)?.replace(
      lbReactRegex,
      '',
    );

    const lbFromEmail =
      (dodSessionSourceValue?.includes('wowcher_email') ||
        window.location.href.includes('/email-deals') ||
        document.location.search.includes('wowcher_email_') ||
        document.location.search.includes('livingsocial_email_')) &&
      !dodSessionSourceValue?.includes('dealzippy_new');

    let channelOK = true;

    if (dodSessionSourceValue?.length > 0) {
      channelOK = !lbSupressedChannels.test(dodSessionSourceValue);
    }

    if (
      channelOK &&
      !urlToExclude.test(document.location.href) &&
      !lbFromEmail &&
      itoValue !== 'wowcher_affiliate_' &&
      itoValue !== 'wowcher_email_' &&
      itoValue !== 'wowcher_retarget_display_rtbhouse' &&
      itoValue !== 'wowcher_retarget_display_criteo' &&
      itoValue !== 'wowcher_wpush' &&
      itoValue !== 'wowcher_native_taboola' &&
      itoValue !== 'tth-dyn-ret-'
    ) {
      console.log('called Digioh lightbox 1');
      window?.DIGIOH_API?.showLB(window.DIGIOH_API.lb_type);
    }
  };

  const setOfflineLightBox = () => {
    if (hideOfflineLightbox) return;
    if (!isOfflineLightbox(router)) return;

    Cookies.save(COOKIES.lbSessionSkip, 'true', {
      ...commonCookiesOptions,
    });

    setShowSubModal(showLightbox);
  };

  useEffect(() => {
    const handleOneTrustAccept = () => {
      console.log('OneTrust button clicked');
      const oneTrustVariable = 'Accepted';
      window.s = window.s || {};
      window.s.eVar92 = oneTrustVariable;
      window.s.tl(true, 'o', 'OneTrust Change');
    };

    const attachEventListener = () => {
      const button = document.querySelector('#onetrust-accept-btn-handler');
      if (button) {
        button.addEventListener('click', handleOneTrustAccept);
        console.log('Event listener attached to the button.');
      }
    };
    if (
      Cookies.load(COOKIES.optConsent) &&
      !Cookies.load(COOKIES.optAlertBox)
    ) {
      attachEventListener();
    } else if (!Cookies.load(COOKIES.optConsent)) {
      const interval = setInterval(() => {
        const cookieValue = Cookies.load(COOKIES.optConsent);
        if (cookieValue) {
          clearInterval(interval);
          attachEventListener();
        }
      }, 1_000);
    }
  }, []);

  useEffect(() => {
    // bypass the lightbox when users are logged in or coming from an awin source
    setShowSubModal(showLightbox && lightbox);
  }, [lightbox, isAuthenticated]);

  useEffect(() => {
    async function fetchDTM() {
      const dtm = await waitForDtm();

      if (!dtm) {
        setDtmAvailable(false);
        setOfflineLightBox();

        return;
      }
      if (dtm) {
        // set cookie to suppress lightbox
        if (!isAuthenticated && isAuthCheckFinished) {
          setCookie({ expire: 365, key: COOKIES.googleOneTap, value: 'b' });
        }
        setShowGeoLocation(true);
        setShowOneTap(true);
      }
      setIsBadConnection(false);
      cookieMonsterInit();
    }
    // Possible values for the cookie
    const values = ['AA', 'BA', 'BB'];
    // Check if the cookie exists
    const existingCookie = Cookies.load(COOKIES.v5_ab);
    const isWowcher = process.env.NEXT_PUBLIC_SITE === BRAND_WOWCHER;
    // If the cookie doesn't exist, generate a random value and set the cookie
    if (!existingCookie && isWowcher) {
      // Generate a random number between 0 and 5 (inclusive)
      const randomIndex = Math.floor(Math.random() * values.length);
      const randomValue = values[randomIndex];
      setCookie({ expire: 365, key: COOKIES.v5_ab, value: randomValue });
    }
    fetchDTM();
  }, []);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_BRAND !== BRAND_WOWCHER &&
      process.env.NEXT_PUBLIC_COUNTRY_CODE.toLowerCase() === 'ie'
    ) {
      // updateLocFromEmail();
    }
    printBlockingScripts();

    getVersionNumber();
    if (process.browser) {
      window.setLightbox = (argument) => {
        dispatch(setLightbox(argument));
      };
    }
  }, []);

  const isWindowDefined = !isSSR();

  useEffect(() => {
    if (isWindowDefined) {
      window.closeSubModal = function () {
        setShowSubModal(false);
      };
    }
  }, [isWindowDefined]);

  useEffect(() => {
    if (urlHistory) updatePersistentProperty('previousPage', urlHistory);
    setUrlHistory(router.asPath);
  }, [router]);

  useEffect(() => {
    const {
      pageType,
      details: { localDealLocation },
    } = parseWowcherPath(router.asPath);

    setTheme(environmentBrand);

    const now = new Date();

    if (isBlackFriday(now)) {
      const brand =
        process.env.NEXT_PUBLIC_BRAND === 'wowcher'
          ? 'wowcher-blackfriday'
          : 'livingsocial-blackfriday';
      setTheme(themes[brand]);
    }

    if (isChristmas(now)) {
      const brand =
        process.env.NEXT_PUBLIC_BRAND === 'wowcher'
          ? 'wowcher-christmas'
          : 'livingsocial-christmas';
      setTheme(themes[brand]);
    }

    if (localDealLocation === 'wellness-collection') {
      setTheme(themes.wellness);
    }

    // set VIP theme for deals which contains siteID of location VIP
    async function fetchVIP() {
      const [, queryString] = router.asPath.split(/\?|#/g);
      const searchParameters = new URLSearchParams(queryString);
      const siteID = searchParameters.get('site');
      const response = await getLocationFromSiteID(siteID);
      if (response?.vip) {
        setTheme(themes.vip);
      }
    }
    if (router.asPath.includes('site=')) {
      fetchVIP();
    }
    if (!dtmAvailable) setOfflineLightBox();
  }, [router.asPath]);

  useEffect(() => {
    const handleStart = (url) => {
      // split of the main path is different to the router path
      if (url.split('?')[0] !== router.asPath.split('?')[0]) {
        setPageIsLoading(true);
      }
    };

    const handleComplete = () => {
      setPageIsLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  useEffect(() => {
    const { pageType } = parseWowcherPath(router.asPath);
    // Do not call this on ppc page as we dont want to set user location to london
    if (pageType !== PAGE_TYPES.ppc) {
      dispatch(getLocation(locations, router));
    }
  }, [dispatch, locations]);

  useEffect(() => {
    const shouldInjectScript =
      (router.pathname.includes('/deals') ||
        router.pathname.includes('/guides') ||
        router.pathname.includes('/deal')) &&
      router.asPath !== '/deals/shop' &&
      process.env.NEXT_PUBLIC_BRAND === 'wowcher';

    const injectVerboliaScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.wowcher.co.uk/buy/scripts/velw.min.js';
      script.async = true;
      script.id = 'verbolia-script';
      document.head.appendChild(script);

      return () => {
        if (script) {
          document.head.removeChild(script);
        }
      };
    };

    const clearPreviousBlocks = () => {
      if (
        window.VerboliaLinking &&
        typeof window.VerboliaLinking.prototype.clearPreviousBlocks ===
          'function'
      ) {
        window.VerboliaLinking.prototype.clearPreviousBlocks();
      }
    };

    if (shouldInjectScript) {
      return injectVerboliaScript();
    } else {
      clearPreviousBlocks();
    }
  }, [router.pathname, router.asPath]);

  useEffect(() => {
    sendBulkDataWhenAuthenticated(isAuthenticated);
  }, [isAuthenticated]);

  const fontsClass = theme?.fonts?.theme
    ? `${theme?.fonts?.theme}, ${theme?.fonts?.base}`
    : `${theme?.fonts?.base}`;

  // We don't want to show deal loading screen for these pages:
  const isShowLoading =
    !isLoginPages(router.pathname) && !isMyaccountPages(router.pathname);

  const [toasts, setToasts] = useState([]);

  const providerValue = useMemo(() => {
    return {
      addToast: (content, status, position, extraClass) => {
        const id = generateUEID();
        setToasts((queue) => [
          { content, extraClass, id, position, status },
          ...queue,
        ]);
      },
      removeToast: (id) => setToasts(toasts.filter((toast) => toast.id !== id)),
    };
  }, []);

  useEffect(() => {
    async function resubscribe() {
      const response = await reSubscribeUsers(customerToken);
      if (response?.data?.valid === true) {
        // set the valid-subscription cookie to false
        setCookie({
          key: COOKIES.validSubscription,
          value: 'false',
        });
        removeCookie(COOKIES.googleOneTap);
        if (isLightBoxAllowed()) {
          setTimeout(() => {
            callDigiohLightbox();
          }, 2_000);
        }
      } else {
        // set the valid-subscription cookie to true
        setCookie({
          key: COOKIES.validSubscription,
          value: 'true',
        });
      }
    }
    if (isAuthenticated && customerToken && !validSubscription) {
      resubscribe();
    }
  }, [isAuthenticated]);
  const toastPosition = toasts[0]?.position || 'top-right';

  return (
    <>
      <ThemeProvider value={theme}>
        <ToastProvider value={providerValue}>
          <PurchaseProvider>
            <Layout {...pageProps} {...Component}>
              {pageIsLoading && isShowLoading ? (
                <LoadingPlaceholder />
              ) : (
                <Component {...pageProps} />
              )}
              {process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' && (
                <ThemeSwitcher
                  changeTheme={(themeName) => setTheme(themes[themeName])}
                />
              )}
              {showSubModal &&
                lightboxAppeared !== 'exists' &&
                isAuthCheckFinished && (
                  <SubscribeModal
                    isBadConnection={isBadConnection}
                    isLightBox
                    list={locations}
                    setShowModal={setShowSubModal}
                    showCloseIcon
                    showModal={showSubModal}
                  />
                )}

              {showOneTap && !isAuthenticated && isAuthCheckFinished && (
                <GoogleOneTap
                  setIsNewUser={setIsNewUser}
                  setUserEmail={setUserEmail}
                />
              )}
              {fireGoogleGeoLocation && showGeoLocation && <GeoLocation />}
              <div ref={ref} />
              <div className={`toast-container ${toastPosition}`}>
                {toasts.map((toast) => (
                  <Toast
                    extraClass={toast.extraClass}
                    key={toast.id}
                    position={toast.position}
                    remove={() => providerValue.removeToast(toast.id)}
                    status={toast.status}
                  >
                    {toast.content}
                  </Toast>
                ))}
              </div>
              <OpenInApp />
            </Layout>
            <style global jsx>{`
              @font-face {
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 400;
                src: local('Nunito Regular'), local('Nunito-Regular'),
                  url('/fonts/nunito-v13-latin-regular.woff2') format('woff2'),
                  url('/fonts/nunito-v13-latin-regular.woff') format('woff');
              }
              body {
                background-color: ${theme?.colors?.background};
                font-family: ${fontsClass};
                font-size: 14px;
                line-height: 1.42857143;
              }
              .sr-only {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border: 0;
              }
            `}</style>
          </PurchaseProvider>
        </ToastProvider>
      </ThemeProvider>
    </>
  );
};

MyApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
};

export default wrapper.withRedux(MyApp);
