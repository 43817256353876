import dayjs from 'dayjs';
import { MAX_LIMITED_AVAILABILITY } from '../config/setup/setup';
import {
  ALMOST_GONE_CUE,
  IM_POPULAR,
  IN_HIGH_DEMAND_CUE,
  LIMITED_AVAILABILITY,
  OVER_THIS_MANY_SOLD,
  RUNNING_OUT,
  SELLING_FAST_CUE,
} from '../config/text/text';

export const roundDownToNearestIncrement = (value, increment) => {
  return (value - (value % increment)).toLocaleString();
};

// ALMOST GONE
export function almostGone(deal, product) {
  return (
    product.quantity > 0 &&
    product.totalRemaining > 0 &&
    product.totalRemaining < 10
  );
}
// LIMITED STOCK AVAILABLE
export function limitedAvailability(deal, product) {
  return (
    product.quantity > 0 &&
    product.totalRemaining >= 10 &&
    product.totalRemaining < MAX_LIMITED_AVAILABILITY
  );
}
// RUNNING OUT
export const runningOut = (deal, product) => {
  return product.quantity > 0 && product.totalRemaining < 100;
};
// OVER THIS MANY SOLD
export const overThisManySold = (deal, product) => {
  return product.quantity > 0 && product.totalBought > 1_000;
};
// IN HIGH DEMAND
export function inHighDemand(deal, product) {
  return product.quantity > 0 && product.totalBought > 250;
}
// IM POPULAR
export const imPopular = (deal, product) => {
  return product.quantity > 0 && product.totalBought > 100;
};

/**
 * @typedef {object}      Rule
 * @property {Function}   match       Does the rule match
 * @property {Function}   message     The essage to show for this rule, takes args (deal, product) => string
 * @property {boolean}    firstOnly   if the rule should be applied to just the first match
 * @property {boolean}    found       if a match has already been found for this rule
 * @returns {Rule[]} An ordered set of rules to be applied if they match
 */
const generateRules = () => [
  // ALMOST GONE - apply to all products that match
  {
    firstOnly: false,
    found: false,
    match: almostGone,
    message: (deal, product) =>
      ALMOST_GONE_CUE.replace('##TOTAL##', product.totalRemaining),
  },
  // LIMITED AVAILABILITY - apply to first product found with no existing message
  {
    firstOnly: true,
    found: false,
    match: limitedAvailability,
    message: () => LIMITED_AVAILABILITY,
  },
  // RUNNING OUT - set on first product found with no existing message
  {
    firstOnly: true,
    found: false,
    match: runningOut,
    message: () => RUNNING_OUT,
  },
  // OVER THIS MANY SOLD - set on first product found with no existing message
  {
    firstOnly: true,
    found: false,
    match: overThisManySold,
    message: (deal, product) =>
      OVER_THIS_MANY_SOLD.replace(
        '##TOTAL##',
        roundDownToNearestIncrement(product.totalBought, 500),
      ),
  },
  // IN HIGH DEMAND - set on first product found with no existing message
  {
    firstOnly: true,
    found: false,
    match: inHighDemand,
    message: (deal, product) =>
      `IN HIGH DEMAND - already ${product.totalBought} sold!`,
  },
  // IM POPULAR - set on first product found with no existing message
  {
    firstOnly: true,
    found: false,
    match: imPopular,
    message: () => IM_POPULAR,
  },
];

/**
 * Get social cue for products in the checkout.
 * Products can only have 1 social cue message, the rules get applied in the order defined above.
 */
export function getRedTextCheckoutSocialCues(deals = []) {
  // fresh rules array to reset found values.
  const rules = generateRules();
  // messages to show {[product.id]: ...message};
  const messages = {};
  // loop all the products
  deals.forEach((deal) => {
    deal.products.forEach((product) => {
      for (const rule of rules) {
        if (rule.match(deal, product)) {
          // we either apply on all that match or on the first that matches depending on firstOnly boolean
          if (!rule.firstOnly || !rule.found) {
            messages[product.id] = rule.message(deal, product);
            rule.found = true;
          }
          // this was a match, move onto the next product
          break;
        }
      }
    });
  });

  return messages;
}

export const getBackendSocialCueMessage = (socialCuesData) => {
  if (!socialCuesData || Object.keys(socialCuesData).length === 0) return null;

  const hour = dayjs().hour();

  if (hour < 6 && socialCuesData.lastTwentyFourHours > 1) {
    return `${socialCuesData.lastTwentyFourHours} others bought this deal in the last 24 hours!`;
  } else if (hour >= 6 && hour < 12 && socialCuesData.lastSixHours > 1) {
    return `${socialCuesData.lastSixHours} others have already bought this deal today!`;
  } else if (hour >= 12 && hour < 18 && socialCuesData.lastTwelveHours > 1) {
    return `${socialCuesData.lastTwelveHours} others have already bought this deal today!`;
  } else if (socialCuesData.lastTwentyFourHours > 1) {
    return `${socialCuesData.lastTwentyFourHours} others bought this deal in the last 24 hours!`;
  } else {
    return null;
  }
};

/**
 * This is the helper that drives the red text on social cue messages for main deal
 * It is used for Main Deal social cue, Deal tile social cue and Calendar social cues.
 *
 * @typedef { deal: object, socialCuesData:object }
 * @property { deal } deal information from deal level
 * @property { socialCuesData } socialCuesData information from social cues API at deal level
 * @returns { string }
 */
export const getRedTextDealSocialCue = ({ deal, socialCuesData = null }) => {
  if (deal.totalRemaining > 0) {
    // ALMOST GONE
    if (deal.totalRemaining < 10) {
      return ALMOST_GONE_CUE.replace('##TOTAL##', deal.totalRemaining);
    }
    // LIMITED STOCK AVAILABLE
    if (deal.totalRemaining < MAX_LIMITED_AVAILABILITY) {
      return LIMITED_AVAILABILITY;
    }
    // IN HIGH DEMAND
    if (deal.totalBought > 100) {
      return IN_HIGH_DEMAND_CUE;
    }
    // SELLING FAST
    if (socialCuesData?.lastTwentyFourHours > 10) {
      return SELLING_FAST_CUE;
    }
  }

  // NO UNITS LEFT
  return '';
};

/**
 * This is the helper that drives the red text on social cue messages for products
 * It is used for Modal on deal page, Dropdown deals, Multiple Select deals and Checkout social cues.
 *
 * @typedef { product: object }
 * @property { product } product information from product level
 * @returns { string }
 */
export const getRedTextProductSocialCue = (product) => {
  if (product.totalRemaining > 0) {
    // ALMOST GONE
    if (product.totalRemaining < 10) {
      return ALMOST_GONE_CUE.replace('##TOTAL##', product.totalRemaining);
    }
    // LIMITED STOCK AVAILABLE
    if (product.totalRemaining < MAX_LIMITED_AVAILABILITY) {
      return LIMITED_AVAILABILITY;
    }
    // RUNNING OUT
    if (product.totalRemaining < 100) {
      return RUNNING_OUT;
    }
    // OVER THIS MANY SOLD
    if (product.totalBought > 1_000) {
      return OVER_THIS_MANY_SOLD.replace(
        '##TOTAL##',
        roundDownToNearestIncrement(product.totalBought, 500),
      );
    }
    // IN HIGH DEMAND
    if (product.totalBought > 250) {
      return IN_HIGH_DEMAND_CUE;
    }
    // IM POPULAR
    if (product.totalBought > 100) {
      return IM_POPULAR;
    }
  }

  // NO UNITS LEFT
  return '';
};
