import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { NAVIGATION } from '../../config/constants/action-types';
import { DEAL_LOCATION_TYPES } from '../../config/constants/page-types';
import {
  BRAND_WOWCHER,
  URLDEAL,
  URLNAVIGATION,
} from '../../config/setup/setup';
import { getAppPlatform } from '../../helpers/device';
import ErrorTrackingService from '../../helpers/errorTrackingService';
import { getLocationShortName } from '../../helpers/location';
import { parseWowcherPath, stripOrigin } from '../../helpers/url';

export const getNavigation = (location, ssr = false) => async (dispatch) => {
  try {
    const shortName = getLocationShortName(location);
    const url = `${URLNAVIGATION}/${shortName}?range=1-30`;
    const resp = await axios(url, {
      headers: {
        'app-platform': getAppPlatform(),
        brand: process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER,
        'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
      },
      method: 'GET',
    });

    const navigation = resp?.data?.map((item) => {
      const itemUrl = stripOrigin(item.url);
      const meta = parseWowcherPath(itemUrl);

      return {
        ...item,
        isLocal: item.linkText.toLowerCase() === 'local',
        isLocalLink: meta?.details?.locationType === DEAL_LOCATION_TYPES.local,
        subNavigations: item.subNavigations.map((sub) => {
          return {
            ...sub,
            url: sub.url,
          };
        }),
        url: item.url,
      };
    });

    dispatch({ list: navigation, ssr, type: NAVIGATION.SET_MENU });

    return true;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return false;
  }
};

export const showDefaultNavigation = () => (dispatch) => {
  dispatch({ ssr: false, type: NAVIGATION.SET_SSR });
};

export const getDealPreviews = (url, count) => async (dispatch) => {
  try {
    const sliceAt = url.indexOf('deals/');
    const route = url.slice(sliceAt + 6);
    const isGifts = url.includes('/gifts');
    const cleanRoute = isGifts
      ? 'gifts'
      : route.replace('shop', 'national-deal');
    const requestUrl = `${URLDEAL}/${cleanRoute}?preview=true&page=0&pageSize=${count}`;

    const resp = await axios(requestUrl, {
      headers: {
        'app-platform': getAppPlatform(),
        brand: process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER,
        'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
        webapp: true,
      },
      method: 'GET',
    });
    dispatch({
      preview: { [url]: resp.data.deals || null },
      type: NAVIGATION.SET_DEAL_PREVIEW,
    });
  } catch {
    dispatch({
      preview: { [url]: null },
      type: NAVIGATION.SET_DEAL_PREVIEW,
    });
  }
};
