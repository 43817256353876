import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import {
  URLDEALPREVIEW,
  URL_FACETED_NAVIGATION_FOR_TRAVEL_HOMEPAGE,
} from '../config/setup/setup';
import ErrorTrackingService from '../helpers/errorTrackingService';
import httpCommonHeaders from '../helpers/httpCommonHeaders';

export const getDealPreviewData = async (uid) => {
  const { data } = await axios(URLDEALPREVIEW(uid), {
    headers: httpCommonHeaders(),
    method: 'GET',
    withCredentials: true,
  });

  return data;
};

export const getFacetedNavigationForTravelHomepage = async () => {
  const site = process.env.NEXT_PUBLIC_SITE;
  const url = URL_FACETED_NAVIGATION_FOR_TRAVEL_HOMEPAGE[site];
  try {
    const { data } = await axios({
      headers: httpCommonHeaders(),
      method: 'GET',
      url,
      withCredentials: true,
    });

    return data || null;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};
