import { BRAND_WOWCHER } from '../config/setup/setup';

export const isLoginPages = (url) => {
  return url === '/login' || url === '/forgot-password' || url === '/register';
};

export const isMyaccountPages = (url) => {
  return url.indexOf('/myaccount') === 0;
};

export const isWowcherBrand = () => {
  return Boolean(process.env.NEXT_PUBLIC_SITE === BRAND_WOWCHER);
};
