/* eslint-disable complexity */
import {
  META_DESCRIPTION,
  TITLE_DEALS_LOCATION,
  TITLE_DEALS_NO_LOCATION,
  TITLE_DEALS_NO_CATEGORY,
  TITLE_DEALS_NO_CATEGORY_NOLOCATION,
  BRAND_WOWCHER,
  TTILE_WELLNESS_DEALS,
  TITLE_ESCAPES_NO_CATEGORY_NOLOCATION,
  TITLE_VIP_HUB,
} from '../config/setup/setup';
import { APP_BANNER_TITLE, TYPE_CATEGORY_DEAL } from '../config/text/text';
import { getCountryCurrency } from './currency';
import { parseFacetedNavigation } from './facetedNavigation';

export const getTextFromMetaDescription = (section, category) => {
  if (META_DESCRIPTION[section]) {
    if (META_DESCRIPTION[section][category])
      return META_DESCRIPTION[section][category];
    if (META_DESCRIPTION[section].default)
      return META_DESCRIPTION[section].default;
  } else {
    return META_DESCRIPTION.default;
  }

  return {};
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getMetaDescriptionFromArray = (aspath, category) => {
  if (!aspath || !aspath.length) return '';
  const arrayData = aspath.split('/');
  if (!arrayData || !arrayData.length) return '';
  if (arrayData[0] === '') arrayData.shift();

  if (
    arrayData[2] &&
    (arrayData[2].toLowerCase() === 'special-event' ||
      arrayData[2].toLowerCase() === 'special')
  ) {
    return getTextFromMetaDescription('special-event', category);
  }

  if (arrayData[arrayData.length - 1].toLowerCase() === 'shop') {
    return getTextFromMetaDescription('shop', category);
  }

  if (arrayData[2] && arrayData[2].toLowerCase() === 'gift-finder') {
    return getTextFromMetaDescription('gift-finder', category);
  }

  if (arrayData[arrayData.length - 1].toLowerCase() === 'travel') {
    return getTextFromMetaDescription('travel', category);
  }

  return getTextFromMetaDescription('local', category);
};

export const getLocationFromUrl = (aspath, locations) => {
  if (!aspath) return null;
  const [url] = aspath.split('?');
  const elements = url.split('/');
  if (!elements || elements.length < 3) return null;
  const elementToSearch = elements[2].toLowerCase();

  return locations.find(
    (item) => item.shortName.toLowerCase() === elementToSearch,
  );
};

export const getPageTitle = ({
  aspath,
  facetedNavigation,
  locationnameAux,
  locations,
  navigation,
  pageTitle = false,
  query = [],
  shortnameAux,
  titletype = '',
}) => {
  /* eslint-disable-next-line sonarjs/cognitive-complexity */

  const LocationAux = getLocationFromUrl(aspath, locations);
  const shortname = LocationAux?.shortName || shortnameAux;
  const locationname = LocationAux?.name || locationnameAux;

  if (aspath.includes('deals/wellness-collection')) {
    return `${TTILE_WELLNESS_DEALS}${
      APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER]
    }`;
  }

  const parsed = parseFacetedNavigation({
    facetedNavigation,
    location: shortname, // location.shortName
    navigation, // navigation
    path: aspath, // router.asPath,
  });

  const resultLinkText = parsed?.defaultItem?.linkText || '';
  const resultSeoTitle =
    parsed?.metaTitle || parsed?.defaultItem?.categoryName || resultLinkText;

  // Version requested by SEO after adding Meta Title

  const categoryAux = parsed?.category || null;
  const categoryToPrint = parsed?.category
    ? parsed?.category === resultSeoTitle
      ? categoryAux
      : `${resultSeoTitle} `
    : null;

  const isLocationIncluded = aspath
    .toLowerCase()
    .includes(shortname.toLowerCase());
  const textToChange = isLocationIncluded
    ? TITLE_DEALS_LOCATION
    : TITLE_DEALS_NO_LOCATION;

  if (pageTitle && titletype === TYPE_CATEGORY_DEAL && query.slug) {
    const isLocalDeal = query.slug[0] !== 'shop' && query.slug[0] !== 'travel';

    return isLocalDeal
      ? `${pageTitle} - ${locationname} - ${
          APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER]
        }`
      : `${pageTitle} - ${
          APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER]
        }`;
  }

  if (aspath === '/deals/vip')
    return `${TITLE_VIP_HUB}${
      APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER]
    } VIP`;

  if (!categoryToPrint?.length && !isLocationIncluded) {
    if (aspath.includes('escapes')) {
      // shortname for escapes on LS is travel not escapes so we need a special case for this
      return `${TITLE_ESCAPES_NO_CATEGORY_NOLOCATION}${
        APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER]
      }`;
    }

    return `${TITLE_DEALS_NO_CATEGORY_NOLOCATION}${
      APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER]
    }`;
  }
  if (!categoryToPrint || !categoryToPrint.length) {
    return `${TITLE_DEALS_NO_CATEGORY.replace('##LOCATION##', locationname)}${
      APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER]
    }`;
  }

  return `${textToChange
    .replace('##CATEGORY##', capitalizeFirstLetter(categoryToPrint))
    .replace('##LOCATION##', locationname)}${
    APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || BRAND_WOWCHER]
  }`;
};

export const getMetaDescription = (
  facetedNavigation,
  aspath, // router.asPath,
  shortnameAux, // location.shortName
  locationnameAux,
  navigation, // navigation
  locations,
) => {
  const LocationAux = getLocationFromUrl(aspath, locations);
  const shortname = LocationAux?.shortName || shortnameAux;
  const locationname = LocationAux?.name || locationnameAux;

  const parsed = parseFacetedNavigation({
    facetedNavigation,
    location: shortname, // location.shortName
    navigation, // navigation
    path: aspath, // router.asPath,
  });

  let text = getMetaDescriptionFromArray(aspath, parsed?.category || '');
  if (!text || !text.length) text = META_DESCRIPTION.default || '';

  // TODO: fill the TRAVELESCAPE & EVENTNAME with the right name
  text = text
    .replace(/##travelescape##/gi, '')
    .replace(/##currency##/gi, getCountryCurrency())
    .replace(
      /##brand##/gi,
      APP_BANNER_TITLE[process.env.NEXT_PUBLIC_SITE || 'wowcher'],
    )
    .replace(/##location##/gi, locationname)
    .replace(/##eventname##/gi, '');

  return text;
};
