import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { TILES } from '../../config/constants/action-types';
import { PAGE_TYPES } from '../../config/constants/page-types';
import { URLTILES } from '../../config/setup/setup';
import { parseWowcherPath } from '../../helpers/url';

export const getTilesApiUrl = (path) => {
  const { details, pageType, pathArray: slug } = parseWowcherPath(path);

  const standardPageTypes = [
    PAGE_TYPES.homepage,
    PAGE_TYPES.vipCategory,
    PAGE_TYPES.category,
  ];

  let location = '';
  let navigation = '';
  let subNavigation = '';
  if (standardPageTypes.includes(pageType)) {
    if (slug[1] !== 'shop') {
      location = slug[1];
    }
    if (slug.length >= 3) {
      navigation = `/${slug.slice(0, 3).join('/')}`;
      if (slug.length > 3) {
        subNavigation = `/${slug.join('/')}`;
      }
    }
  } else {
    location = details.location || '';
    navigation = path;
  }

  return `${URLTILES}?brand=${process.env.NEXT_PUBLIC_BRAND}&location=${location}&navigation=${navigation}&subNavigation=${subNavigation}`;
};

export const setTiles = (tiles, apiUrl) => (dispatch) => {
  return dispatch({ apiUrl, tiles, type: TILES.SET_TILES });
};

export const getTiles = (path, currentApiUrl, currentNumberTiles) => async (
  dispatch,
) => {
  const isDevelopment = process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev';
  if (isDevelopment) return null;
  const apiUrl = getTilesApiUrl(path);
  // no need to get same results again
  if (currentApiUrl === apiUrl && currentNumberTiles !== 0) return false;
  try {
    const response = await axios(apiUrl, {
      method: 'GET',
      withCredentials: false,
    });
    const data = response.data || [];

    if (data?.error) {
      throw new Error('Tiles api error: ' + data.error);
    }
    if (Array.isArray(data)) {
      data.apiUrl = apiUrl;
      dispatch(setTiles(data, apiUrl));

      return data;
    } else {
      throw new TypeError('Tiles api unexpected data');
    }
  } catch (error) {
    console.error('Tiles: ', error);
  }
};
