// eslint-disable-next-line filenames/match-exported
import { CHECKOUT } from '../../config/constants/action-types';
import { getFiveMinInFuture } from '../../helpers/timer';

export const CHECKOUT_STATUS = {
  LOADING: 'LOADING', // loading data
  READY: 'READY',
  // calling api for fresh data via timer
  REFRESHED: 'REFRESHED',
  // loaded and countdown active
  REFRESHING: 'REFRESHING', // loaded fresh checkout data via timer
  UPDATING: 'UPDATING', // values are being updted (this blocks the timer refresh)
};

const initialState = {
  checkoutData: {
    deals: [],
    orderSummary: {},
    promoCode: {},
    userInfo: {
      paymentOptions: {
        creditBalances: {},
        paymentInstruments: [],
      },
    },
    wallet: false,
  },
  countdown: getFiveMinInFuture(),
  giftingConfig: {},
  giftPackPreset: {},
  giftWrapPreset: {},
  isTravel: false,
  isTravelValid: false,
  queryParams: {
    deliveryOptions: true,
    gifting: true,
    giftPacks: true,
    por_check: true,
    promoCode: null,
    vipChecked: false,
    wallet: false,
  },
  status: CHECKOUT_STATUS.LOADING,
};

const checkoutReducer = (
  state = initialState,
  {
    type,
    status,
    checkoutData,
    giftPackPreset,
    giftWrapPreset,
    queryParams,
    isTravel,
    isTravelValid,
    giftingConfig,
  },
) => {
  switch (type) {
    case CHECKOUT.SET_CHECKOUT_STATUS: {
      return {
        ...state,
        status,
      };
    }
    case CHECKOUT.CHECKOUT_API_PARAMS: {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...queryParams,
        },
      };
    }
    case CHECKOUT.SET_CHECKOUT: {
      return {
        ...state,
        checkoutData,
        giftingConfig,
        giftPackPreset,
        giftWrapPreset,
      };
    }
    case CHECKOUT.RESET_COUNTDOWN: {
      return {
        ...state,
        countdown: getFiveMinInFuture(),
      };
    }
    case CHECKOUT.SET_TRAVEL: {
      return {
        ...state,
        isTravel,
      };
    }
    case CHECKOUT.SET_TRAVEL_VALID: {
      return {
        ...state,
        isTravelValid,
      };
    }
    default:
      return state;
  }
};

export default checkoutReducer;
