/* eslint-disable promise/prefer-await-to-then */
import { useState } from 'react';
import Cookies from 'react-cookies';
import useSWR from 'swr';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import {
  DEAL_LOCATION_TYPES,
  PAGE_TYPES,
  TRAVEL_DEAL_QUALIFIERS,
  NATIONAL_DEAL_QUALIFIERS,
  VIP_QUALIFIERS,
} from '../config/constants/page-types';
import COOKIES from '../config/cookies/cookies';
import { DESKTOP_PAGE_SIZE, SEARCH_PAGE_SIZE } from '../config/links/links';
import {
  URLPRODUCTLISTINGPAGEDEAL,
  SEARCHURL,
  FILTER_SUBCATEGORY,
  FILTER_ALL,
  FILTER_CATEGORY,
  FILTER_SORTBY,
  FILTER_MINPRICE,
  FILTER_MAXPRICE,
  FILTER_LOCATION,
  FILTER_ORDER,
  FILTER_TAG,
  URLPRODUCTDETAILPAGEDEAL,
  FILTER_VIP,
} from '../config/setup/setup';
import { getSecondLetter, trackEvent, trackPage } from './analytics';
import commonCookiesOptions from './commonCookiesOptions';
import { removeCookie } from './cookieSetter';
import { isFiltered } from './filters';
import { getIsNewApi } from './giftfinder';
import httpCommonHeaders from './httpCommonHeaders';
import { getStoredDealsByCategory } from './recentlyViewed';
import {
  sendSponsoredDealImpressions,
  SponsoredImpressionType,
} from './sponsoredSearch';
import { isSSR } from './ssr';
import { parseWowcherPath } from './url';

const API_SEARCHPARAMS_WHITELIST = [
  FILTER_CATEGORY,
  FILTER_LOCATION,
  FILTER_SORTBY,
  FILTER_MINPRICE,
  FILTER_MAXPRICE,
  FILTER_VIP,
];

const API_GIFT_FINDER_PARAMS_WHITELIST = [
  ...API_SEARCHPARAMS_WHITELIST,
  FILTER_TAG,
  'giftFor',
];

const DEAL_API_LOCATION_PATH = {
  national: 'national-deal',
  nearMe: 'near-me',
  travel: 'travel',
};

export const getGiftForUrl = (baseUrl, path, apiParameters) => {
  if (getIsNewApi()) {
    return `${baseUrl}/${path}/gifts`;
  }

  const BASE_URL = `${baseUrl}/${path}/gift-finder`;
  let url = BASE_URL;

  if (apiParameters.has('giftFor')) {
    const giftForValue = apiParameters.get('giftFor').toString();
    apiParameters.delete('giftFor');
    url = `${BASE_URL}/gifts-for-${giftForValue}`;
  }

  return url;
};

// eslint-disable-next-line complexity
export const getDealApiPath = ({
  currentPagePath,
  dealIdOverride,
  // only set this flag for category pages
  isCategoryPage = false,
  page = 0,
  pageSize = DESKTOP_PAGE_SIZE,
  offset = 0,
  stringOutput = true,
  emailDealLocation,
  isEmailDeal,
  sideDealsLocal,
  // ignoreFilters used for the top of the cat page -- should always show non filtered deals
  ignoreFilters = false,
}) => {
  const { searchParams, details, pageType, pathArray } = parseWowcherPath(
    currentPagePath,
  );
  let emailLocation;

  const isDealsPage = pathArray?.includes('deals');

  if (isEmailDeal && emailDealLocation) {
    emailLocation = emailDealLocation?.shortName;
  }
  if (
    ![
      PAGE_TYPES.deal,
      PAGE_TYPES.dealPreview,
      PAGE_TYPES.category,
      PAGE_TYPES.homepage,
      PAGE_TYPES.vipDeal,
      PAGE_TYPES.vipCategory,
      PAGE_TYPES.gifts,
    ].includes(pageType)
  ) {
    throw new Error('Unsupported page type');
  }

  const {
    locationType,
    localDealLocation,
    isEvergreen,
    isTravel,
    isTravelHomepage,
    isHyperlocal,
    category,
    subCategory,
    dealId: dealIdInUrl,
    vipLocation,
  } = details;

  if (isEvergreen && !dealIdOverride)
    throw new Error('dealIdOverride must be specified on Evergreen path');

  const locationCookie = Cookies.load(COOKIES?.location);

  const v5_ab = Cookies.load(COOKIES.v5_ab);
  let secondLetter = null;

  // Check if the cookie exists and has at least two characters
  if (v5_ab && v5_ab.length >= 2) {
    secondLetter = getSecondLetter(v5_ab);
  }

  const osp = secondLetter === 'B' || secondLetter === 'C';
  const userLocation = locationCookie?.shortName || localDealLocation;

  let location;
  if (isTravel) {
    location = DEAL_API_LOCATION_PATH.travel;
  } else if (isEmailDeal) {
    location = emailLocation;
  } else if (sideDealsLocal) {
    location = userLocation;
  } else if (isHyperlocal) {
    location = DEAL_API_LOCATION_PATH.nearMe;
  } else if (locationType === DEAL_LOCATION_TYPES.national) {
    location = DEAL_API_LOCATION_PATH.national;
  } else if (
    locationType === DEAL_LOCATION_TYPES.local &&
    localDealLocation &&
    isDealsPage
  ) {
    location = localDealLocation;
  } else if (locationType === DEAL_LOCATION_TYPES.local && localDealLocation) {
    location = userLocation;
  } else if (VIP_QUALIFIERS.includes(pageType)) {
    location = vipLocation;
  } else {
    throw new Error('Unable to parse deal type / location');
  }

  let apiSubCategory = subCategory;
  if (!ignoreFilters) {
    const querySubCategory = searchParams.get(FILTER_SUBCATEGORY);
    if (querySubCategory === FILTER_ALL) {
      apiSubCategory = '';
    } else if (querySubCategory) {
      apiSubCategory = querySubCategory;
    }
  }

  const currentPagePathLength = currentPagePath
    ?.replace(/^\/|\/$/g, '')
    .split('/').length;

  const useHyperLocalSpecial = isHyperlocal && currentPagePathLength < 4;

  // Reference: https://public-api05.devwowcher.co.uk/docs/index.html#!/deal/dealsByLocation
  let apiPathArray = [];
  if (isTravelHomepage) {
    apiPathArray = [location, apiSubCategory];
  } else {
    apiPathArray = [
      location,
      useHyperLocalSpecial ? 'special' : '',
      category,
      useHyperLocalSpecial ? '' : apiSubCategory,
      isHyperlocal ? pathArray[pathArray.length - 1] : '',
      dealIdOverride ?? dealIdInUrl,
    ];
  }

  apiPathArray = apiPathArray.filter((item) => item);

  const apiSearchParameters = new URLSearchParams();

  if (!ignoreFilters) {
    searchParams.forEach((value, key) => {
      if (API_SEARCHPARAMS_WHITELIST.includes(key)) {
        apiSearchParameters.append(key, value);
      }
    });

    // pricelow is a special case
    if (searchParams.get(FILTER_SORTBY) === 'pricelow') {
      apiSearchParameters.set(FILTER_SORTBY, 'price');
      apiSearchParameters.set(FILTER_ORDER, 'asc');
    }
  }

  apiSearchParameters.set('page', page);
  apiSearchParameters.set('pageSize', pageSize);
  apiSearchParameters.set('offset', offset);

  if (
    osp &&
    isCategoryPage &&
    pathArray.length === 2 &&
    (locationType === DEAL_LOCATION_TYPES.local ||
      TRAVEL_DEAL_QUALIFIERS.includes(pathArray[1]) ||
      NATIONAL_DEAL_QUALIFIERS.includes(pathArray[1]))
  ) {
    // const marketingCloudVisitorID =
    //   typeof window !== 'undefined' ? window.s?.marketingCloudVisitorID : '';

    // if (!Cookies.load(COOKIES.customerToken) && marketingCloudVisitorID) {
    //   apiSearchParameters.append('visitorId', marketingCloudVisitorID);
    // }
    const categoryType = locationType || (isTravel ? 'travel' : null);
    if (categoryType) {
      getStoredDealsByCategory(categoryType)
        ?.slice(0, 5)
        ?.forEach((id) => apiSearchParameters.append('recentViewedDeal', id));
    }
  }

  if (stringOutput) {
    return `${
      isCategoryPage ? URLPRODUCTLISTINGPAGEDEAL : URLPRODUCTDETAILPAGEDEAL
    }/${apiPathArray.join('/')}?${apiSearchParameters.toString()}`;
  } else {
    return {
      baseUrl: isCategoryPage
        ? URLPRODUCTLISTINGPAGEDEAL
        : URLPRODUCTDETAILPAGEDEAL,
      path: apiPathArray.join('/'),
      searchParams: apiSearchParameters,
    };
  }
};

export const getSpecialApiPath = ({
  location = 'national-deal',
  slug = '',
}) => {
  const apiSearchParameters = new URLSearchParams();
  apiSearchParameters.set('pageSize', SEARCH_PAGE_SIZE);

  const path = `${location}/special/${slug}`;

  return {
    baseUrl: URLPRODUCTLISTINGPAGEDEAL,
    path,
    searchParams: apiSearchParameters,
    toString: () =>
      `${URLPRODUCTLISTINGPAGEDEAL}/${path}?${apiSearchParameters.toString()}`,
  };
};

export const getSearchApiPath = ({
  query,
  location,
  pageSize = SEARCH_PAGE_SIZE,
}) => {
  const apiSearchParameters = new URLSearchParams();

  new URLSearchParams(query).forEach((value, key) => {
    if (['q', ...API_SEARCHPARAMS_WHITELIST].includes(key)) {
      apiSearchParameters.append(key, value);
    }
  });

  // pricelow is a special case
  if (apiSearchParameters.get(FILTER_SORTBY) === 'pricelow') {
    apiSearchParameters.set(FILTER_SORTBY, 'price');
    apiSearchParameters.set(FILTER_ORDER, 'asc');
  }

  apiSearchParameters.set('pageSize', pageSize);

  return {
    baseUrl: SEARCHURL,
    path: location,
    searchParams: apiSearchParameters,
  };
};

export const getGiftFinderApiPath = ({ query, location, pageSize = 12 }) => {
  const apiSearchParameters = new URLSearchParams();

  new URLSearchParams(query).forEach((value, key) => {
    if ([...API_GIFT_FINDER_PARAMS_WHITELIST].includes(key)) {
      apiSearchParameters.append(key, value);
    }
  });

  // pricelow is a special case
  if (apiSearchParameters.get(FILTER_SORTBY) === 'pricelow') {
    apiSearchParameters.set(FILTER_SORTBY, 'price');
    apiSearchParameters.set(FILTER_ORDER, 'asc');
  }

  apiSearchParameters.set('pageSize', pageSize);

  return {
    baseUrl: URLPRODUCTLISTINGPAGEDEAL,
    path: location,
    searchParams: apiSearchParameters,
  };
};

export const fetchSearchMeta = async (url) => {
  const response = await axios(url, {
    headers: httpCommonHeaders(),
    method: 'GET',
  });

  return {
    categoryOptions: response?.data?.facetedNavigation?.navigations.map(
      (item) => {
        return {
          name: `${item.linkText} (${item.count})`,
          value: item.shortName,
        };
      },
    ),
    count: response?.data?.facetedNavigation?.count,
    deals: response?.data?.deals,
    searchResult: response?.data.searchResult,
    sessionId: response?.data?.sponsored?.sessionId || null,
    sponsored: response?.data?.sponsored?.deals || [],
  };
};

export const useSearchMeta = ({ location }) => {
  const [count, setCount] = useState(0);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [sponsoredSearch, setSponsoredSearch] = useState([]);
  const [ready, setReady] = useState(false);
  const { baseUrl, path, searchParams: apiParameters } = getSearchApiPath({
    location: location.shortName,
    pageSize: 1,
    query: isSSR() ? '' : window.location.search,
  });
  apiParameters.append('facetedNavigation', 'true');

  const url = `${baseUrl}/${path}?${apiParameters.toString()}`;

  useSWR(url, fetchSearchMeta, {
    onSuccess: (data) => {
      setReady(true);

      let sponsoredSearchDeals = [];
      if (!isFiltered() && data.sponsored) {
        sponsoredSearchDeals = data.sponsored.map((deal, index) => {
          deal.isSponsored = true;
          // we need to add src here because the indedx is required
          deal.urlPath = `${deal.urlPath}?usr_src=sponsored_search_${
            index + 1
          }`;

          return deal;
        });
      }

      // use state to avoid the values clearing while evaluating the next search
      setCount(data.count ?? 0);
      setCategoryOptions(data.categoryOptions ?? []);
      setSponsoredSearch(sponsoredSearchDeals);

      // track sponosred deal impressions
      sendSponsoredDealImpressions(
        SponsoredImpressionType.impression,
        data.sponsored,
      );

      if (data.sessionId) {
        if (Cookies.load(COOKIES.sessionId)) {
          removeCookie(COOKIES.sessionId);
        }

        Cookies.save(COOKIES.sessionId, data.sessionId, commonCookiesOptions);
      }

      if (isFiltered()) {
        trackEvent('search_filtered_fired');
      }

      // track on a successful search result
      trackPage({
        categories: apiParameters.getAll(FILTER_CATEGORY).join(','),
        filter: null,
        location,
        price: `${apiParameters.get(FILTER_MINPRICE) || 0}-${
          apiParameters.get(FILTER_MAXPRICE) || 5_000
        }`,
        searchResults: data.count ?? 0,
        searchTerms: apiParameters.get('q'),
        sort: apiParameters.get(FILTER_SORTBY) || 'popularity',
        sponsoredSearch: data.sponsored.map((deal) => deal.id).join(','),
      });
    },
    // only get the results once -- no revalidating
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { categoryOptions, count, ready, sponsoredSearch };
};

export const useGiftFinderMeta = ({ location, pageSize }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [count, setCount] = useState(-1);
  const [deals, setDeals] = useState([]);
  const [ready, setReady] = useState(false);

  const { baseUrl, searchParams: apiParameters, path } = getGiftFinderApiPath({
    location: location.shortName,
    pageSize,
    query: isSSR() ? '' : window.location.search,
  });

  apiParameters.append('facetedNavigation', 'true');
  const url = getGiftForUrl(baseUrl, path, apiParameters);

  const updatedUrl = `${url}?${apiParameters.toString()}`;

  const { data: dataSWR } = useSWR(updatedUrl, fetchSearchMeta, {
    onSuccess: (data) => {
      // use state to avoid the values clearing while evaluating the next search
      trackPage({
        categories: apiParameters.getAll(FILTER_CATEGORY).join(','),
        filter: null,
        location,
        pageName: 'Gift finder',
        price: `${apiParameters.get(FILTER_MINPRICE) || 0}-${
          apiParameters.get(FILTER_MAXPRICE) || 5_000
        }`,
        searchResults: data.count ?? 0,
        sort: apiParameters.get(FILTER_SORTBY) || 'popularity',
      });
      setReady(true);
      setCount(data.count ?? 0);
      setDeals(data?.deals);
      setCategoryOptions(data.categoryOptions ?? []);
    },
  });

  return {
    categoryOptions,
    count,
    deals,
    ready,
    searchResult: dataSWR?.searchResult || 0,
    updatedUrl,
  };
};
