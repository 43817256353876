/* eslint-disable filenames/match-exported */
import { DEALS } from '../../config/constants/action-types';

export const FEATURED_DEALS_LOADED = {
  COMPLETE: 'COMPLETE',
  UNCOMPLETE: 'UNCOMPLETE',
};

const initialState = {
  canonicalUrl: null,
  deals: [],
  evergreenUrl: null,
  facetedNavigation: {},
  featuredDealsLoaded: FEATURED_DEALS_LOADED.UNCOMPLETE,
  isDealPreview: false,
  lastId: 0,
  lastPage: null,
  mainDeal: {},
  newProductDeals: [],
  nomore: false,
  recentlyViewedDeals: [],
  recommendedDeals: [],
  redirectUrl: null,
  searchResult: null,
  secondaryDealsArray: [],
  ssr: true,
};

const dealsReducer = (
  state = initialState,
  {
    type,
    mainDeal,
    facetedNavigation,
    deals,
    evergreenUrl,
    newdeals,
    secondaryDealsArray,
    ssr,
    recommendedDeals,
    newProductDeals,
    recentlyViewedDeals,
    featuredDealsLoaded,
    isDealPreview,
    lastPage,
    lastId,
    canonicalUrl,
    redirectUrl,
    searchResult,
  },
) => {
  switch (type) {
    case DEALS.SET_DEALS: {
      return {
        ...state,
        canonicalUrl,
        deals,
        evergreenUrl,
        facetedNavigation: JSON.parse(JSON.stringify(facetedNavigation)),
        lastId: 0,
        lastPage: null,
        mainDeal,
        nomore: false,
        redirectUrl,
        searchResult,
        secondaryDealsArray,
        ssr,
      };
    }
    case DEALS.SET_ONLY_MAIN_DEAL: {
      return {
        ...state,
        mainDeal,
      };
    }
    case DEALS.SET_IS_DEAL_PREVIEW: {
      return {
        ...state,
        isDealPreview,
      };
    }
    case DEALS.SET_FOR_DEAL_PREVIEW: {
      return {
        ...state,
        canonicalUrl,
        deals,
        evergreenUrl,
        facetedNavigation: JSON.parse(JSON.stringify(facetedNavigation)),
        lastId: 0,
        lastPage: null,
        nomore: false,
        redirectUrl,
        searchResult,
        secondaryDealsArray,
        ssr,
      };
    }
    case DEALS.SET_WITHOUT_MAIN_DEAL: {
      return {
        ...state,
        canonicalUrl,
        deals,
        facetedNavigation: JSON.parse(JSON.stringify(facetedNavigation)),
        ssr,
      };
    }
    case DEALS.SET_EXTRA_DEALS: {
      return {
        ...state,
        deals: state.deals.concat(newdeals),
        ssr: false,
      };
    }
    case DEALS.SET_NO_MORE_DEALS: {
      return {
        ...state,
        lastPage,
        nomore: true,
      };
    }
    case DEALS.SET_LAST_ID: {
      return {
        ...state,
        lastId,
      };
    }
    case DEALS.SET_RECOMMENDED_DEALS: {
      return {
        ...state,
        recommendedDeals,
      };
    }
    case DEALS.SET_NEW_PRODUCT_DEALS: {
      return {
        ...state,
        newProductDeals,
      };
    }
    case DEALS.SET_RECENTLY_VIEWED_DEALS: {
      return {
        ...state,
        recentlyViewedDeals,
      };
    }
    case DEALS.SET_ALL_FEATURED_DEALS_LOADED: {
      return {
        ...state,
        featuredDealsLoaded,
      };
    }
    case DEALS.SET_ONLY_SSR: {
      return {
        ...state,
        ssr,
      };
    }
    default:
      return state;
  }
};

export default dealsReducer;
