export const centerItemInNav = (element) => {
  if (element) {
    const nav = document.querySelector('nav');
    if (nav) {
      const center = Math.ceil(nav.offsetWidth / 2);
      const { offsetLeft, offsetWidth } = element;
      const left = offsetLeft - (center - Math.ceil(offsetWidth / 2));
      nav.scrollTo({ behavior: 'smooth', left });
    }
  }
};
